import {StepDto} from "../dto/tyre-scanner-configuration-dto";

export enum StepType {
  REFERENCE = 'REFERENCE',
  WEAR = 'WEAR'
}

export class Step {
  position: number;
  type: StepType;

  constructor(position: number, type: StepType) {
    this.position = position;
    this.type = type;
  }

  toString() {
    return this.position + '_' + this.type;
  }

  static fromString(stepAsString: string) {
    return new Step(+this.isolatePositionFromStep(stepAsString),
      this.isolateTypeFromStep(stepAsString) as StepType);
  }

  static getPositionFromStepDto(stepDto: StepDto): number {
    let position = -1;
    switch (stepDto) {
      case StepDto.LEFT_FRONT: position = 1; break;
      case StepDto.RIGHT_FRONT: position = 2; break;
      case StepDto.RIGHT_REAR: position = 3; break;
      case StepDto.LEFT_REAR: position = 4; break;
    }
    return position;
  }

  private static isolateTypeFromStep(step: string): StepType {
    const type: string = step ? step.split('_').slice(1).join('_') : '';
    return StepType[type as keyof typeof StepType];
  }

  private static isolatePositionFromStep(step: string) {
    return step ? step.split('_')[0] : '';
  }
}
