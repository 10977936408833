import {Step, StepType} from "./step";
import {CarDriverPosition} from "../dto/country-settings-dto";
import {StepDto} from "../dto/tyre-scanner-configuration-dto";

export class Scenario {

  steps: Step[] = [];
  axles: number = 0;
  isRightDriver = false;

  isOneTyreScenario() {
    return new Set(this.steps.map(s => s.position)).size === 1;
  }

  isFirstStep(position: number) {
    return this.steps[0]?.position === position;
  }

  hasStepType(type: StepType) {
    return !!this.steps.find((step) => step.type === type);
  }

  static ScenarioBuilder = class {

    private readonly scenario: Scenario;
    private stepTypes: StepType[] = [];
    private onlyOneTyrePerAxle = false;
    private carDriverPosition = 'LEFT';

    constructor() {
      this.scenario = new Scenario();
    }

    setAxles(axles: number) {
      this.scenario.axles = axles;
      return this;
    }

    setStepTypes(stepTypes: StepType[]) {
      this.stepTypes = stepTypes;
      return this;
    }

    setOnlyOneTyrePerAxle(onlyOneTyrePerAxle: boolean) {
      this.onlyOneTyrePerAxle = onlyOneTyrePerAxle;
      return this;
    }

    setCarDriverPosition(carDriverPosition: CarDriverPosition) {
      this.carDriverPosition = carDriverPosition;
      return this;
    }

    setStepsFromStepDto(referenceSteps: StepDto[], wearSteps: StepDto[]) {
      const axlePositions = new Set<number>();

      if (referenceSteps) {
        for (let referenceStep of referenceSteps) {
          const position = Step.getPositionFromStepDto(referenceStep);
          this.scenario.steps.push(new Step(position, StepType.REFERENCE));
          axlePositions.add(position);
        }
      }

      if (wearSteps) {
        for (let wearStep of wearSteps) {
          const position = Step.getPositionFromStepDto(wearStep);
          this.scenario.steps.push(new Step(position, StepType.WEAR));
          axlePositions.add(position);
        }
      }

      const axleGroups = [[1, 2], [4, 3]];
      let axleCount = 0;
      for (const [pos1, pos2] of axleGroups) {
        if (axlePositions.has(pos1) || axlePositions.has(pos2)) {
          axleCount++;
        }
      }
      this.scenario.axles = axleCount;
      return this;
    }

    /**
     * Car tyre rotation is
     * 1 --- 2
     * |     |
     * 4 --- 3
     */
    build() {
      if (this.scenario.steps.length === 0) {
        this.initializeSteps();
      }

      if (!this.onlyOneTyrePerAxle) {
        this.sortScenario();
      }

      this.scenario.isRightDriver = this.carDriverPosition === 'RIGHT';
      return this.scenario;
    }

    private initializeSteps() {
      const tyreNumber = this.scenario.axles * 2;

      for (let axle = 0; axle < this.scenario.axles; axle++) {
        for (let step of this.stepTypes) {
          this.addStepsForAxle(axle, step, tyreNumber);
        }
      }
    }

    private addStepsForAxle(axle: number, step: StepType, tyreNumber: number) {
      const secondTyreAxle = axle + 2;
      const rearTyre = tyreNumber - axle + 1;

      // Front
      if (axle === 0) {
        this.scenario.steps.push(new Step(1, step));
        if (!this.onlyOneTyrePerAxle) {
          this.scenario.steps.push(new Step(secondTyreAxle, step));
        }
      } else {
        if (!this.onlyOneTyrePerAxle) {
          this.scenario.steps.push(new Step(secondTyreAxle, step));
        }
        this.scenario.steps.push(new Step(rearTyre, step));
      }
    }

    private sortScenario() {
      if (this.carDriverPosition === 'RIGHT') {
        const order = [2, 1, 4, 3];
        this.scenario.steps.sort((a, b) => order.indexOf(a.position) - order.indexOf(b.position));
      } else {
        this.scenario.steps.sort((a, b) => a.position - b.position);
      }
    }
  }

  static CAR_SCENARIO(carDriverPosition: CarDriverPosition = 'LEFT'){
    return new Scenario.ScenarioBuilder()
      .setCarDriverPosition(carDriverPosition)
      .setAxles(2)
      .setStepTypes(Object.values(StepType))
      .build();
  }
  static TWO_TYRES_SCENARIO(types: StepType[]) {
    return new Scenario.ScenarioBuilder().setAxles(2).setStepTypes(types).setOnlyOneTyrePerAxle(true).build();
  }
  static SINGLE_TYRE_SCENARIO(types: StepType[]) {
    return new Scenario.ScenarioBuilder().setAxles(1).setOnlyOneTyrePerAxle(true).setStepTypes(types).build();
  }

}
