import { uuid4 } from './misc.js';

/**
 * Generate a random, valid trace ID.
 */
function generateTraceId() {
  return uuid4();
}

/**
 * Generate a random, valid span ID.
 */
function generateSpanId() {
  return uuid4().substring(16);
}
export { generateSpanId, generateTraceId };
