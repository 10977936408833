import {Injectable} from '@angular/core';
import {dexie} from "../../../dexie";
import {Picture, PictureStatus} from "../../core/models/picture";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {

  private async withRetry(action: () => Promise<any> | any, extraInfo?: any) {
    try {
      return await action();
    } catch (e) {
      this.log(e, extraInfo);
      try {
        return await action();
      } catch (e) {
        this.log(e, extraInfo, true);
      }
    }
  }

  private log(e: unknown, extraInfo?: any, retry: boolean = false) {
    console.error(e, extraInfo, retry);
    Sentry.captureException(e, {
      extra: {
        ...(extraInfo && { extraInfo }),
        ...(retry && { message: 'Failed IndexedDB retry' })
      }
    });
  }

  async changePicturesStatus(status: PictureStatus) {
    await this.withRetry(async () => {
      await dexie.pictures?.orderBy('').modify({ status });
    }, ['changePicturesStatus', status]);
  }

  async changePictureStatus(picture: Picture, status: PictureStatus) {
    await this.withRetry(async () => {
      await dexie.pictures?.where('[step.position+step.type]')
        .equals([picture.step.position, picture.step.type])
        .modify({ status });
    }, ['changePictureStatus', picture, picture.data?.byteLength, status]);
  }

  async addPicture(picture: Picture) {
    await this.withRetry(async () => {
      await dexie.pictures?.add(picture, picture.step.toString());
    }, ['addPicture', picture, picture.data?.byteLength]);
  }

  async bulkAdd(pictures: Picture[]) {
    await this.withRetry(async () => {
      await dexie.pictures?.bulkAdd(pictures, pictures.map((p) => p.step.toString()));
    }, ['bulkAdd', pictures, pictures.map((p) => p.data?.byteLength)]);
  }

  async addPictureIfNotPresent(picture: Picture) {
    await this.withRetry(async () => {
      const _picture = await this.getPicture(picture.step.toString());
      if (!_picture) await this.addPicture(picture);
    }, ['addPictureIfNotPresent', picture, picture.data?.byteLength]);
  }

  async getPicture(key: string): Promise<Picture | undefined> {
    return (await this.withRetry(async () => {
      const picture = await dexie.pictures?.get(key);
      return picture || Promise.resolve(undefined);
    }, ['getPicture', key])) || Promise.resolve(undefined);
  }

  async getAllPictures(): Promise<Picture[]> {
    return await this.withRetry(async () => {
      const pictures = await dexie.pictures?.orderBy('')?.toArray();
      return pictures?.map((picture) => Picture.fromDB(picture)) || [];
    }, 'getAllPictures');
  }

  async getAllPicturesByStatus(status: PictureStatus): Promise<Picture[]> {
    return await this.withRetry(async () => {
      const pictures = await dexie.pictures?.where({ status })?.toArray();
      return pictures?.map((picture) => Picture.fromDB(picture)) || [];
    }, ['getAllPicturesByStatus', status]);
  }

  async deletePicture(key: string) {
    await this.withRetry(async () => await dexie.pictures?.delete(key), ['deletePicture', key]);
  }

  async deleteAllPictures() {
    await this.withRetry(() => dexie.pictures?.clear(), 'deleteAllPictures');
  }
}
