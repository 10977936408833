export interface TyreScannerConfigurationDto {
  enable: boolean;
  hookActivated: boolean;
  enableGetVehicleInfo: boolean;
  referenceTyresScenario: StepDto[];
  wearTyresScenario: StepDto[];
}

export enum StepDto {
  LEFT_FRONT = 'LEFT_FRONT',
  RIGHT_FRONT = 'RIGHT_FRONT',
  RIGHT_REAR = 'RIGHT_REAR',
  LEFT_REAR = 'LEFT_REAR'
}
