import {HttpBackend, HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, firstValueFrom, map, switchMap, throwError} from "rxjs";
import {environment} from "src/environments/environment";
import {Utils} from "../../shared/utils/generics.utils";
import {WebappConfigurationDto} from "../dto/webapp-configuration-dto";
import {FeaturesConfigurationDto} from "../dto/features-configuration-dto";
import {ConfigurationDto} from "../dto/configuration-dto";
import {ReportConfigurationDto} from "../dto/report-configuration-dto";
import {TyreScannerConfigurationDto} from "../dto/tyre-scanner-configuration-dto";
import {FleetConfigurationDto} from "../dto/fleet-configuration-dto";
import {DiagnosisConfigurationDto} from "../dto/DiagnosisConfigurationDto";
import {FleetcheckConfigurationDto} from "../dto/fleetcheck-configuration-dto";
import {Amplify} from "aws-amplify";
import {UiUtils} from "../../shared/utils/ui.utils";
import {WebReportConfigurationDto} from "../dto/web-report-configuration-dto";
import * as Sentry from "@sentry/angular";
import {AppointmentConfigurationDto} from "../dto/appointment-configuration-dto";
import {StepType} from "../models/step";

@Injectable()
export class ConfigService {
  config?: Config = initialState;
  readonly mercureUrl = 'https://ca-rool.com/.well-known/mercure';
  private http: HttpClient;
  private readonly legals = 'https://www.carool.tech/cgu'

  constructor(private readonly handler: HttpBackend) {
    this.http = new HttpClient(this.handler);
  }

  configureAmplify(config: Config) {
    Amplify.configure({
      Auth: {
        Cognito: {
          ...config.cognito.auth,
          signUpVerificationMethod: 'code'
        }
      }
    });
  }

  setTheme(config: Config) {
    let root = document.documentElement;
    config.webapp.isLightTheme ? root.setAttribute('class', 'light-theme'): root.setAttribute('class', 'colored-theme');
  }

  setFavicon(config: Config) {
    let favicon = document.createElement('link');
    favicon.rel = "icon";
    favicon.type = "image/x-icon";
    favicon.href = config.webapp.faviconImage;
    document.querySelector('head')?.appendChild(favicon);
  }

  setFont(config: Config) {
    let font = new FontFace('MyFont', 'url('+config.report.font+')', { style: 'normal', weight: '400', display: 'swap' });
    let fontBold = new FontFace('MyFont', 'url('+config.report.fontBold+')', { style: 'normal', weight: '700', display: 'swap' });
    (document.fonts as any).add(font);
    (document.fonts as any).add(fontBold);
    font.load().then();
    fontBold.load().then();
  }

  setGoogleKeys(config: Config) {
   if (
     (config.webapp.isProEnabled || config.appointment.enable) &&
     config.google.placesApiKey
   ) {
     let script = document.createElement('script');
     script.src = `https://maps.googleapis.com/maps/api/js?key=${config.google.placesApiKey}&libraries=places&callback=doNothing`
     document.querySelector('head')?.appendChild(script);
   }
  }

  setCustomCss(config: Config) {
    let link = document.createElement('link');
    link.href = config.webapp.customCss;
    link.rel = 'stylesheet';
    document.querySelector('head')?.appendChild(link);
  }

  loadConfig() {
    return firstValueFrom(this.http.get<Config>(`${environment.defaultConfigFile}`).pipe(
      switchMap((config: Config) => {
        this.config = config;
        this.configureAmplify(config);
        return this.http.get<ConfigurationDto>(`${config.rool.configurationApi.url}/customers/${Utils.getCompanyFromDomain()}/configurations`)
      }),
      catchError((err) => {
        const company = Utils.getCompanyFromDomain();
        if (err.status === 404 && company.includes('-')) {
          Sentry.captureException(new Error('Unable to load company'), {extra: {company}})
          const fallbackCompany = company.split('-')[0];
          return this.http.get<ConfigurationDto>(`${this.config!.rool.configurationApi.url}/customers/${fallbackCompany}/configurations`)
        }
        return throwError(() => err);
      }),
      switchMap((configDto: ConfigurationDto) => {
        if(this.config) {
          this.config.features = configDto.feature;
          this.config.webapp = configDto.webapp;
          this.config.report = configDto.report;
          this.config.tyreScanner = configDto.tyreScanner;
          this.config.fleet = configDto.fleet;
          this.config.diagnosis = configDto.diagnosis;
          this.config.fleetcheck = configDto.fleetcheck;
          this.config.webReport = configDto.webReport;
          this.config.appointment = configDto.appointment;

          this.setCustomCss(this.config);
          this.setTheme(this.config);
          this.setFavicon(this.config);
          this.setFont(this.config);
          this.setGoogleKeys(this.config);
        }

        return this.http.get<any>(this.config?.webapp.themeFile!,{responseType: 'json'});
      }), map((ui) => {
        let root = document.documentElement;
        Object.keys(ui).forEach(function(key){
          root.style.setProperty(key, ui[key]);
        });
      })
    ))
  }

  getConfig() {
    return this.config || initialState;
  }

  getFeatures() {
    return this.getConfig().features;
  }

  getWebapp() {
    return this.getConfig().webapp;
  }

  getTyreScanner() {
    return this.getConfig().tyreScanner;
  }

  getFleet() {
    return this.getConfig().fleet;
  }

  getDiagnosis() {
    return this.getConfig().diagnosis;
  }

  getFleetCheck() {
    return this.getConfig().fleetcheck;
  }

  getReport() {
    return this.getConfig().report;
  }

  getWebReport() {
    return this.getConfig().webReport;
  }

  getAppointment() {
    return this.getConfig().appointment;
  }

  getEnv() {
    return this.getConfig().app.environment;
  }

  hasPhoneIdentification() {
    return this.getWebapp().showPhoneIdentification;
  }

  hasIdentification() {
    return this.getWebapp().showPhoneIdentification || this.getWebapp().showMailIdentification;
  }

  getLegalsUrl() {
    const browserLang = UiUtils.getBrowserLanguage();
    return browserLang === 'fr' ? this.legals : this.legals.substring(0, this.legals.lastIndexOf('/')) + '/en' + this.legals.substring(this.legals.lastIndexOf('/'));
  }
  getPrivacyUrl() {
    return this.getFeatures().privacyUrl;
  }

}

export interface Config {
  app: {
    environment: string;
    disableDate?: string; // ISO format,
    final_step?: boolean;
    final_step_url?: string;
  };
  rool: {
    referenceApi: {
      url: string;
    };
    appointmentApi: {
      url: string;
    };
    configurationApi: {
      url: string;
    };
    fleetApi: {
      url: string;
    };
    externalApi: {
      url: string;
    };
  };
  webapp: WebappConfigurationDto,
  features: FeaturesConfigurationDto,
  report: ReportConfigurationDto,
  tyreScanner: TyreScannerConfigurationDto,
  fleet: FleetConfigurationDto,
  diagnosis: DiagnosisConfigurationDto,
  fleetcheck: FleetcheckConfigurationDto,
  webReport: WebReportConfigurationDto,
  appointment: AppointmentConfigurationDto,
  cognito: {
    auth: {
      region: string;
      userPoolId: string;
      userPoolClientId: string;
    }
  },
  google: {
    placesApiKey: string;
  }
}

export const initialState: Config = {
  app: {
    environment: ''
  },
  rool: {
    referenceApi: {
      url: ''
    },
    appointmentApi: {
      url: ''
    },
    configurationApi: {
      url: ''
    },
    fleetApi: {
      url: ''
    },
    externalApi: {
      url: ''
    },
  },
  webapp: {
    logoImage: '',
    logoHeight: 0,
    faviconImage: '',
    isProEnabled: false,
    isEmbedded: false,
    showMailIdentification: false,
    showPhoneIdentification: false,
    analysisExpirationDuration: 90,

    isLightTheme: false,
    themeFile: '',
    showLandingTitle: false,
    logoBackgroundColor: '',
    languages: [],
    logoBorderColor: '',
    secondLogoImage: '',
    languagesFiles: [],
    adsBanner: undefined,
    customCss: '',
    enableCBDViews: false,
    enableSentry: false,
    betaFeaturesEnabled: false
  },
  features: {
    winterReglementationDetails: false,
    winterReglementationUrl: '',
    ctuCurrentVersion: 0,
    notSupportedImage: '',
    referenceMask: '',
    wearMask: '',
    displayCompanyName: '',
    enableEndUserOptInConsentCollect: false,
    privacyUrl: '',
    licenseAutorizedCountries: []
  },
  report: {
    font: '',
    fontBold: '',
    changeTyresLink: ''
  },
  tyreScanner: {
    enable: false,
    hookActivated: true,
    enableGetVehicleInfo: false,
    referenceTyresScenario: [],
    wearTyresScenario: []
  },
  fleet: {
    enableTyreHostel: false,
    enableGeolocation: false
  },
  diagnosis: {
    enable: true,
    mileageMandatory: false,
    enableReminder: true,
    enableSpareTyreAnalysis: false,
    enableNewDiagnosisWithOtherLicense: true,
    phoneAuthorizedCountries: [],
    enableSharing: true,
    landingImage1: '',
    landingImage2: '',
    referenceTutorialImage: '',
    wearTutorialImage: ''
  },
  fleetcheck:{
    enableReportPage: false
  },
  webReport: {
    reportAccessStrategies: []
  },
  appointment: {
    enable: false,
    desktopProviderAdsImage: ''
  },
  cognito: {
    auth: {
      region: '',
      userPoolId: '',
      userPoolClientId: ''
    }
  },
  google: {
    placesApiKey: ''
  }
};
