import {inject, Injectable, signal} from '@angular/core';
import {BehaviorSubject, filter, fromEvent, Observable, startWith} from 'rxjs';
import {ConfigService} from "../../core/config";
import {Utils} from "../utils/generics.utils";
import {UiUtils} from "../utils/ui.utils";
import {CookiesConsentService} from "../components/cookies-consent/services/cookies-consent.service";
import {StoreService} from "./store.service";
import moment from "moment/moment";
import {TranslateService} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private configService = inject(ConfigService);
  private cookiesService = inject(CookiesConsentService);
  private store = inject(StoreService);
  private translate = inject(TranslateService);

  private readonly _sessionStorage = sessionStorage;
  private readonly LANGUAGE_KEY = 'lang';

  private hasFooter$ = new BehaviorSubject<boolean>(!this.configService.getWebapp().isEmbedded);

  private logoHeight$ = new BehaviorSubject<string | undefined>(this.configService.getWebapp().logoHeight + 'px');

  private logoImage$ = new BehaviorSubject<string>(this.configService.getWebapp().logoImage);

  private logoBackgroundColor$ = new BehaviorSubject<string | undefined>(this.configService.getWebapp().logoBackgroundColor);

  private logoBorderColor$ = new BehaviorSubject<string | undefined>(this.configService.getWebapp()?.logoBorderColor);

  private language$ = new BehaviorSubject<string>(this.getDefaultLanguage());

  private readonly orientationChanged$ = new BehaviorSubject<'landscape' | 'portrait'>(UiUtils.isLandscape() ? 'landscape' : 'portrait');
  readonly _orientationChanged = this.orientationChanged$.asObservable();
  private lockSupported?: boolean;
  private readonly embedded$ = new BehaviorSubject<boolean | undefined>(this.configService.getWebapp().isEmbedded);
  readonly _embedded = this.embedded$.asObservable();

  private _screenWidth = signal(0);
  screenWidth = this._screenWidth.asReadonly();
  private _screenHeight = signal(0);
  screenHeight = this._screenHeight.asReadonly();

  constructor() {
    this.initRotationListener();

    // By default accept cookies in embedded mode.
    this._embedded.pipe(filter((value) => value !== undefined)).subscribe((embedded) => {
      if (embedded) {
        this.cookiesService.acceptCookies();
        this.hideFooter();
      }
    });

    fromEvent(window, 'resize')
      .pipe(
        takeUntilDestroyed(),
        startWith(undefined)
      )
      .subscribe(() => {
        this.onResize();
    });
  }

  onResize() {
    this._screenWidth.set(window.innerWidth);
    this._screenHeight.set(window.innerHeight);
  }

  showFooter() {
    if (!this.isEmbedded()) {
      this.hasFooter$.next(true);
    }
  }

  hideFooter() {
    this.hasFooter$.next(false);
  }

  getFooterStatus(): Observable<boolean> {
    return this.hasFooter$.asObservable();
  }

  getLogoHeight(): string | undefined {
    return this.logoHeight$.getValue();
  }

  getLogoImage(): Observable<string> {
    return this.logoImage$.asObservable();
  }

  getLogoBackgroundColor(): string | undefined {
    return this.logoBackgroundColor$.getValue();
  }

  getLogoBorderColor(): string | undefined {
    return this.logoBorderColor$.getValue();
  }

  getStepPositionAsString(position: number, axle: number): string {
    let stringPosition = position.toString();
    // Return string position only if there is 2 axle (ie: 4 tyre position ).
    if (axle === 2) {
      switch (position) {
        case 1:
          stringPosition = 'frontLeft';
          break;
        case 2:
          stringPosition = 'frontRight';
          break;
        case 3:
          stringPosition = 'rearRight';
          break;
        case 4:
          stringPosition = 'rearLeft';
          break;
      }
    } else if (axle === 1) {
      switch (position) {
        case 1:
          stringPosition = 'frontLeft';
          break;
        case 2:
          stringPosition = 'frontRight';
          break;
      }
    }
    return stringPosition;
  }

  setEmbedded() {
    this.embedded$.next(true);
    this.hideFooter();
  }

  isEmbedded() {
    return this.embedded$.getValue() || this.configService.getWebapp().isEmbedded;
  }

  getCommunicationKey(): string {
    let key;
    if (this.configService.getWebapp().showMailIdentification && this.configService.getWebapp().showPhoneIdentification) {
      key = '_all'
    } else if (this.configService.getWebapp().showMailIdentification) {
      key = '_mail'
    } else if (this.configService.getWebapp().showPhoneIdentification) {
      key = '_phone'
    } else {
      key = '_no'
    }
    return key;
  }

  getDisplayedCompanyName() {
    const displayName = this.configService.getFeatures().displayCompanyName;
    return Utils.isBlank(displayName) ? this.store.getCompany()?.name : displayName;
  }

  setLanguage(language?: string | null) {
    if (language) {
      this._sessionStorage.setItem(this.LANGUAGE_KEY, language);
      this.applyLanguage(language);
    } else {
      this.applyLanguage(this.getLanguage());
    }

  }

  getLanguage() {
    return this.language$.getValue();
  }

  private getDefaultLanguage() {
    const sessionLanguage = this._sessionStorage.getItem(this.LANGUAGE_KEY);
    return sessionLanguage ? sessionLanguage : UiUtils.getBrowserLanguage();
  }

  private applyLanguage(language: string) {
    if (this.configService.getConfig().webapp.languages?.includes(language)) {
      this.translate.use(language);
      this.language$.next(language);
      moment.locale(language);
      document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'auto');
    } else {
      this.language$.next(this.translate.getDefaultLang());
    }
  }

  async isLockSupported() {
    let support = this.lockSupported;
    if (support == undefined) {
      support = true;
      try {
        const type = screen.orientation.type;
        // @ts-ignore
        await screen.orientation.lock(type);
        await screen.orientation.unlock();
      } catch (e) {
        if ((e as Error).name !== 'SecurityError') {
          support = false;
        }
      }
      this.lockSupported = support;
    }
    return support;
  }

  private initRotationListener() {
    if (Utils.isOS()) {
      window.onorientationchange = () => {
        this.orientationChanged$.next(UiUtils.isLandscape() ? 'landscape' : 'portrait');
      }
    } else {
      screen.orientation.addEventListener('change', () => {
        this.orientationChanged$.next(UiUtils.isLandscape() ? 'landscape' : 'portrait');
      });
    }
  }

}
